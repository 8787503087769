@import "definitions";

/*Error Formular*/
$form_error_color: #d63323;

/*Meldungen und Anzeigen*/
$action_info_bg: #4B69A7;
$action_info_warning: #ea8101;
$action_info_success: green;
$action_font_color: #fff;

.action_info{  

    background-color: $action_info_bg;
    &.warning {
        background-color:$action_info_warning;
        color:#fff;
    }
    &.error {
        background-color:$form_error_color;
        color:#fff;
    }
    &.success {
        background-color:$action_info_success;
        color:#fff;
    }
    padding: $pad_small $pad;
    
    //margin: $mar_small 0; Causes problems in inline situations

    span{
        color: $action_font_color;
    }
    > .info{
        @include sl-icon($fa-var-info-circle);
        margin-right: $mar_small;
        color: $action_font_color;
    }

    &.narrow {
        display:inline-block;
    }
}

span.action_info {
    display: inline-block;
}

/*Error Formular*/

.form_error{
    color: $form_error_color;
    margin-top: $mar_small;
}

/*Newsletter*/

div.newsletter_send {
    &>div {
        margin-bottom: $mar;
    }
}
